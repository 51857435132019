<template>
  <div class="show" :style="{height:freshHeight +'px','overflow-y':'scroll','box-sizing': 'border-box'}">
    <van-form @submit="saveCusInfo()" validate-trigger="saveCusInfo" @failed="onFailed" :rules="customerForm">
      <div class="cusInfo">
        <van-row>
          <van-col span="24" justify="center" align="center">
            <van-field required :rules="customerForm.customerName" v-model="customerInfo.customerName"
                       input-align="right" label="客户名称："/>
            <van-field required readonly v-model="customerInfo.enterpriseType" input-align="right" label="企业类型："/>
            <van-field required readonly v-model="customerInfo.enterpriseNature" input-align="right" label="企业性质："/>
            <van-field readonly v-model="customerInfo.customerLevels" input-align="right" label="授信等级："/>
          </van-col>
        </van-row>
      </div>
      <div class="conInfo">
        <van-row>
          <van-col span="12">
            <div>
              <van-field v-model="customerInfo.contacts" label="姓名 :"/>
            </div>
          </van-col>
          <van-col span="12">
            <div>
              <van-field v-model="customerInfo.contactsPost" label="职务 ："/>
            </div>
          </van-col>
        </van-row>
        <van-row>
          <div>
            <van-field span="24" v-model="customerInfo.contactsPhone" label="电话 :"/>
          </div>
        </van-row>
        <van-row>
          <div>
            <van-field span="24" v-model="addressDetailInfo.addressDetailed" label="地址：" @click="showMap"/>
          </div>
        </van-row>
      </div>
      <div class="addCon">
        <van-cell is-link @click="showPopup()" title="更多地址/联系人" title-class="addCon-text"/>
      </div>
      <div class="remark">
        <van-field
            v-model="customerInfo.remarks"
            rows="2"
            autosize
            label="备注"
            type="textarea"
            maxlength="50"
            placeholder="请输入备注"
            show-word-limit
        />
      </div>
      <div class="shipInfo">
        <van-cell is-link @click="showShipInfo()">船舶信息</van-cell>
      </div>
      <van-button type="primary" v-if="isHeaden" size="large" class="footer-bar">保存</van-button>
    </van-form>
    <!-- 底部导航栏-->
    <crm-tab-bar :tabpage="5"/>

    <!-- 百度地图位置选择 -->
    <BMapAddressSelect :addressDetailInfo="this.addressDetailInfo" ref="bmapAddressSelect"
                       @confirmMapAddress="confirmMapAddress"></BMapAddressSelect>
  </div>
</template>

<script>

import BMapAddressSelect from "@/components/BMapAddressSelect/index";
import CrmTabBar from "@/components/CrmTabBar";
import {Toast} from "vant";

export default {
  name: "CustomerListEditCF",
  components: {CrmTabBar, BMapAddressSelect},
  data() {
    return {
      isHeaden: false,
      //地址信息
      addressDetailInfo: {
        addressDetailed: '',
        addressLongitude: '',
        addressLatitude: '',
        addressProvince: '',
        addressCity: '',
        district: ''
      },
      //客户信息
      customerInfo: {},
      //列表高度
      freshHeight: 0,
      levelCompany: '',
      customerForm: {
        customerName: [
          {required: true, message: '请输入客户名称', trigger: 'blur'},
        ],
        enterpriseType: [
          {required: true, message: '请选择客户类型', trigger: 'blur'},
        ],
        enterpriseNature: [
          {required: true, message: '请选择客户性质', trigger: 'blur'},
        ],
        contactsPhone: [
          {
            pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            message: "请输入正确的电话",
            trigger: "blur",
          },
        ],
      },
    }
  },
  methods: {
    init() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner'
      });
      this.$ajax.post('/api/ajax/bulkCrmsCusList/getBulkCrmsCfCusInfo.json',
          {crmCustomerid: this.$route.params.name}).then(res => {
        if (res.status == 200) {
          console.log(res.data)
          this.customerInfo = res.data[0]
          this.addressDetailInfo.addressDetailed = this.customerInfo.addressDetailed
          this.addressDetailInfo.addressLongitude = this.customerInfo.addressLongitude
          this.addressDetailInfo.addressLatitude = this.customerInfo.addressLatitude
          this.addressDetailInfo.addressProvince = this.customerInfo.addressProvince
          this.addressDetailInfo.addressCity = this.customerInfo.addressCity
          this.levelCompany = res.data[0].customerGrade == '一' ? '二级公司列表' : (res.data[0].customerGrade == '二' ? '一级公司列表' : '暂无主子公司')
          if (res.data[0].customerGm == this.$store.getters.userId) {
            this.isHeaden = true;
          }
          Toast.success('加载成功');
        }
      }).catch(err => {
        Toast.fail('加载失败');
        console.log(err)
        this.$message.error('查询失败！');
      })
    },
    showPopup() {
      if (this.customerInfo.addressOtherid === '') {
        this.$router.push({path: '/BulkCrmsCusAddressDetailList/' + this.customerInfo.crmCustomerid})
      } else {
        this.$router.push({path: '/BulkCrmsCusAddressDetailList/' + this.customerInfo.addressOtherid})
      }
    },
    showShipInfo() {
      // this.$router.push("/BulkCrmsShipInfoList")
      var url =  this.customerInfo.shipInformationid.length > 0 ? this.customerInfo.shipInformationid : "no"
      console.log(url)
      this.$router.push({path: '/BulkCrmsShipInfoList/' + url})
    },
    /** 显示地图 */
    showMap() {
      console.log(this.addressDetailInfo.addressDetailed)
      if (this.addressDetailInfo.addressDetailed.length > 0 || this.addressDetailInfo.addressDetailed != "") {

      } else {
        this.$refs.bmapAddressSelect.show();
      }
    },
    /** 确认地图地址 */
    confirmMapAddress(addressDetailInfo) {
      this.addressDetailInfo.addressDetailed = addressDetailInfo.addressDetailed;
      this.addressDetailInfo.addressLongitude = addressDetailInfo.addressLongitude;
      this.addressDetailInfo.addressLatitude = addressDetailInfo.addressLatitude;
      this.addressDetailInfo.addressProvince = addressDetailInfo.addressProvince;
      this.addressDetailInfo.addressCity = addressDetailInfo.addressCity;
    },
    saveCusInfo() {
      this.$ajax.post('/api/ajax/bulkCrmsCusList/updateBulkCrmsCustomertwo.json',
          {
            customerName: this.customerInfo.customerName,
            contacts: this.customerInfo.contacts,
            contactsPost: this.customerInfo.contactsPost,
            contactsPhone: this.customerInfo.contactsPhone,
            crmCustomerid: this.customerInfo.crmCustomerid,
            addressLongitude: this.addressDetailInfo.addressLongitude,
            addressLatitude: this.addressDetailInfo.addressLatitude,
            addressDetailed: this.addressDetailInfo.addressDetailed,
            addressProvince: this.addressDetailInfo.addressProvince,
            addressCity: this.addressDetailInfo.addressCity,
            remarks: this.customerInfo.remarks,
            modifiedPeople: this.$store.getters.userId,
          }).then(res => {
        if (res.status == 200) {
          this.$message({
            showClose: true,
            message: '修改成功',
            type: 'success'
          });
          sessionStorage.clear();
          setTimeout(() => {
            this.$router.replace({path: '/CustomerList'})
          }, 200);
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('修改失败');
      })
    },
    onFailed(errorInfo) {
      console.log('failed', errorInfo);
      this.$message.warning(errorInfo.errors[0].message);
    },
  },
  mounted() {
    this.freshHeight = document.documentElement.clientHeight - 50
    this.init()
  },

}

</script>

<style scoped>

.card {
  margin: 10px 5px;
  background: #378FF2;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

.cusInfo {
  margin: 10px 5px;
  padding: 5px;
  border-radius: 6px;
  background: white;
  box-shadow: 6px 6px 5px #888888;
}

.conInfo {
  margin: 10px 5px;
  padding: 5px;
  border: 1px solid #5CA4F4;
  border-radius: 6px;
  background: white;
  box-shadow: 6px 6px 5px #888888;
}

.remark {
  margin: 5px 5px;
  padding: 3px;
  border-radius: 5px;
  background: white;
}

.addCon {
  color: #5CA4F4;
  text-align: left;
}

.qtyPrf {
  margin: 10px 5px;
  background: white;
  border-radius: 6px;
  box-shadow: 6px 6px 5px #888888;
}

</style>
<!--样式穿透 不能在<style scoped>中修改已给定样式，可以使用样式穿透-->
<style>
.cusInfo .van-field__control--right {
  color: #5CA4F4;
  font-size: 13px;
}

.cusInfo .van-field, .qtyPrf .van-field, .conInfo .van-field {
  padding-top: 3px;
  height: 30px;
  font-size: 12px;
}

.shipInfo {
  padding-top: 3px;
  font-size: 12px;
}

.voyshipInfo {
  padding-top: 6px;
  font-size: 12px;
}

.addCon .van-cell {
  color: #5CA4F4;
  font-size: 12px;
}

.van-button {
  width: 220px;
  height: 35px;
  border-radius: 5px;
  font-weight: bold;
  font-family: 新宋体;
}

</style>
